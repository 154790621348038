$card-margin: 20px;

@mixin square-card($size) {
  flex: 0 0 $size;
  height: $size;
  margin: $card-margin;

  &:first-child {
    margin-top: $card-margin;
  }
}

a.ui.card {
  transition: 0.1s background ease, 0.1s color ease, 0.1s text-shadow ease,
    box-shadow 0.1s ease, transform 0.1s ease, -webkit-box-shadow 0.1s ease,
    -webkit-transform 0.1s ease;
}

.ui.card.-centred > .content {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ui.card.-square {
  @include square-card(150px);
}

.ui.card.-whitey {
  background-color: $whitey;

  @at-root a#{&}:hover {
    background-color: tint($whitey, 30%);
  }
}

.ui.card.-active {
  background-color: $lightGold;
  color: white;
  transform: translateY(-3px);
  @include pop-text;

  @at-root a#{&}:hover {
    background-color: $lightGold;
  }

  > .content > .header {
    color: inherit;
  }
}

.card-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -$card-margin;
}
