.header-body-page {
  $space: 0 4% 40px;

  > .ui.header,
  > .body {
    padding: $space;
  }

  > .ui.message {
    margin: $space;
  }

  > .ui.header {
    border-bottom: 1px solid $lightSilver;
  }
}
