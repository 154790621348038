@mixin link() {
  color: $azul;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.2s ease, background-color 0.2s ease;

  &:hover {
    color: tint($azul, 30%);
  }

  &.-block {
    color: inherit;
    display: block;
    width: 100%;
  }

  &.-block:hover {
    background-color: transparentize(white, $amount: 0.7);
  }

  &.-semantic {
    display: inline-block;
    color: $textColour;
    height: 100%;
    width: 100%;
  }

  &.-active {
    color: $lightGold;
  }
}

@mixin button-tertiary($colour: $midnightBlue) {
  background: none;
  box-shadow: none;
  color: tint($colour, 30%);
  $side-padding: 0.25em;
  padding-left: $side-padding;
  padding-right: $side-padding;
  text-shadow: none;

  &:hover {
    background: none;
    box-shadow: none;
    color: $colour;
    text-shadow: none;
  }
}

@mixin pop-text {
  text-shadow: $popTextShadow;
}
