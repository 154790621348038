$tinySize: 12px;
$smallSize: 16px;
$mediumSize: 24px;
$largeSize: 32px;

.ico-moon,
.flexpit-svg-icon,
.custom-icon,
.sl-icon {
  &.-tiny {
    height: $tinySize;
    width: auto;
  }

  &.-small {
    height: $smallSize;
    width: auto;
  }

  &.-medium {
    height: $mediumSize;
    width: auto;
  }

  &.-large {
    height: $largeSize;
    width: auto;
  }
}

.ico-moon {
  &.-active {
    fill: $lightGold;
  }
}

.flexpit-svg-icon {
  display: inline-block;
}

.animated-icon.-fist-bump {
  display: inline-block;

  &.-small {
    @include animation-fist-bump($smallSize + $smallSize / 3);
  }

  &.-medium {
    @include animation-fist-bump($mediumSize + $mediumSize / 3);
  }

  &.-large {
    @include animation-fist-bump($largeSize + $largeSize / 3);
  }
}
