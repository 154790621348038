.ui.menu > .item.-fluid {
  flex: 1 1 auto;
}

.ui.menu > .item.-centre {
  justify-content: center;
}

.ui.menu.header-bar {
  $height: 62px;
  background-color: $whitey;
  border: none !important;
  height: $height;
}
