.fp-sidebar-layout {
  display: flex;
  flex-direction: row;
  height: 100%;

  > .fp-sidebar.ui.menu {
    flex: 0 0 auto;
    position: static;
  }

  > .fp-sidebar.ui.menu > .item.header {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 20px;
  }

  > .fp-sidebar > .item > .fp-title {
    color: white;
  }

  > .fp-sidebar.ui.menu > .item > .link {
    font-weight: bold;
    padding: 10px 30px;
    @include link;
  }

  > .fp-sidebar.ui.menu > .item {
    padding: 0;
  }

  > .main-container {
    flex: 1 1 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
