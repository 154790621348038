@mixin hovering-trans {
  transition: 0.4s background, 0.2s border, 0.2s box-shadow, 0.4s color;
}

@mixin appear-initial {
  opacity: 0.01;
  transform: scale(0.5, 0.5);
}

@mixin appear-active {
  opacity: 1;
  transform: scale(1, 1);
  transition: 0.4s opacity, 0.4s transform;
}

@mixin disappear-initial {
  opacity: 0.99;
  opacity: 0;
}

@mixin disappear-active {
  opacity: 0;
  transition: 0.4s opacity;
}

$defaultTransitionDuration: 0.2s;
$defaultTransitionEase: ease;

@mixin sprite-animation($imageUrl, $size, $nbSteps, $time) {
  background: url($imageUrl) no-repeat;
  background-size: auto $size;
  background-position: 0 0;
  height: $size;
  width: $size;

  &.-animate {
    transition: background $time steps($nbSteps - 1);
  }

  &.-animated {
    background-position: ($nbSteps - 1) * -$size;
  }
}

@mixin animation-fist-bump($size: 100px, $time: 0.5s) {
  @include sprite-animation(
    '../assets/icons/flexpit/FistbumpSprite.png',
    $size,
    28,
    $time
  );
}
