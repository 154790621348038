.ui.form.-vcentre > .fields {
  > .field {
    align-items: center;
    display: flex;
  }

  &.inline > .field {
    display: inline-flex;
  }
}

.ui.form > .fields > .field.-fluid {
  flex: 1 1 auto;
}
