.post-block {
  > .content {
    padding: 20px 15px;
  }

  > .content > .section {
    margin-bottom: 15px;
  }
}

.post-grid {
  $margin: 10px;
  display: flex;
  flex-wrap: wrap;
  margin: -$margin / 2;

  > .ui.card.post {
    $post-size: 170px;
    height: $post-size;
    margin: $margin;
    width: $post-size;
  }

  > .nodatamessage {
    margin: $margin;
  }
}

.ui.comments.fix-shaking {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
