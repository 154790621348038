.drop-zone {
  margin-bottom: 20px;
  min-height: 300px;
}

.drop-zone > .zone {
  align-items: center;
  background-color: $veryLightSilver;
  border: 2px dashed $silver;
  border-radius: $borderRadius;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;

  > .ui.progress {
    width: 85%;
  }

  > .label {
    color: $grey;
  }

  > .ortext {
    color: tint($grey, 15%);
    text-transform: uppercase;
  }

  > .ui.button,
  > .label,
  > .ortext {
    margin: 5px;
  }

  > .supportedfiles {
    margin-top: 10px;
    text-align: center;
  }

  > .supportedfiles > .text {
    margin: 0;
  }

  &.-active {
    border: 2px solid;
  }

  &.-acceptingfile {
    border-color: $azul;
  }

  &.-rejectingfile {
    border-color: $bloodOrange;
  }
}
