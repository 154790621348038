// Make the modal as high as needed but not more
.scrolling-modal-container > .ui.modals.dimmer > .ui.modal.scrolling {
  margin: 3.5rem auto auto !important;
}

// Trigger scroll on page for small screen
@media only screen and (max-height: 768px) {
  .scrolling-modal-container > .ui.modals.dimmer > .ui.modal.scrolling {
    margin: 0rem auto auto !important;
  }
}
