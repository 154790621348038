.strong {
  font-weight: bold;

  &.-positive {
    color: $aquamarine;
  }

  &.-negative {
    color: $bloodOrange;
  }
}
