.ui.statistics.-card {
  $side-margin: 1em;
  $card-height: 150px;
  $card-min-width: 180px;

  // justify-content: space-between;
  margin-left: -$side-margin !important;
  margin-right: -$side-margin !important;

  > .ui.statistic {
    background-color: $whitey;
    margin-left: $side-margin;
    margin-right: $side-margin;
    flex: 1 1;
    justify-content: center;
    height: $card-height;
    min-width: $card-min-width;
    padding: 10px;
  }

  > .ui.statistic > .value {
    margin: 0.4em 0;
  }
}
