.ui.dropdown.-fromright {
  &.floating > .menu,
  > .menu {
    left: auto;
    right: 0;
  }
}

.clearable-dropdown {
  display: inline-block;
  position: relative;

  > .clearbutton {
    // Position
    position: absolute;
    top: 0;
    right: 2em;
    bottom: 0;
    line-height: 1;
    margin: auto;
    z-index: 1;
  }

  > .link.icon.clearbutton {
    color: $silver;
    opacity: 1;
    transition: color 0.2s ease;

    &:hover {
      color: shade($silver, 15%);
      opacity: 1;
    }
  }
}
