.ui.modal {
  > .content > .section {
    margin-bottom: 15px;
  }
}

.ui.modal.-notpadded {
  > .content {
    padding: 0;
  }

  // uhm okay but me no want ugly messages!!! so margin
  > .content > .message,
  > .content > .forcepadding {
    margin: 20px;
  }
}

.popup-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 102;
}
