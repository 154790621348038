/**
 * Lightens a colour.
 */
@function tint($colour, $percentage) {
  @return mix(white, $colour, $percentage);
}

/**
 * Darkens a colour.
 */
@function shade($colour, $percentage) {
  @return mix(black, $colour, $percentage);
}
