.user-verif-warning-msg {
  margin-top: 15px;
  margin-bottom: 15px;
  font-style: italic;
  font-size: 13px;
}

.user-verif-no-doc {
  color: $bloodOrange;
}
