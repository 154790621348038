.ui.list > .item.-inline {
  > .header,
  > .description {
    display: inline-block;
  }

  > .header + .description {
    margin-left: 5px;
  }
}
