.ui.button.-block {
  border-radius: 0;
  height: 100%;
}

/* Button list */
.button-list {
  align-items: center;
  display: inline-flex;
  $margin: 5px;
  margin-bottom: -$margin;

  > .ui.button {
    margin-right: 15px;
    margin-bottom: $margin;
  }
}

.ui.header > .button-list,
.ui.header > .content > .button-list {
  margin-left: 40px;
}

.ui.header > .ui.button,
.ui.header > .content > .ui.button {
  margin-left: 20px;
}

.button-list {
  &.-block {
    display: flex;
  }
}

.button-list,
.ui.button {
  &.-spaced {
    margin-bottom: 15px;
  }
}

.ui.button.-tertiary {
  @include button-tertiary;

  &.primary {
    @include button-tertiary($azul);
  }

  &.secondary {
    @include button-tertiary($moodySky);
  }

  &.positive,
  &.green {
    @include button-tertiary($aquamarine);
  }

  &.negative,
  &.red {
    @include button-tertiary($bloodOrange);
  }
}
