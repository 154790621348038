// Base design colours
$azul: #1f53ea;
$moodySky: #242d3d;
$midnightBlue: #0c0033;
$blueBlue: #143dcc;
$bluePurple: #4000ff;
$lightGold: #ffc754;
$aquamarine: #00d3a7;
$bloodOrange: #ff443a;
$whitey: #f0f0f0;

$grey: tint($midnightBlue, 50%);
$silver: tint($midnightBlue, 75%);
$lightSilver: tint($midnightBlue, 90%);
$veryLightSilver: tint($midnightBlue, 96%);

$textColour: $midnightBlue;

// Utils
$popTextShadow: 0 1px 2px rgba(0, 0, 0, 0.2);
