.ui.basic.segment.notification-line {
  $height: 50px;
  align-items: center;
  background-color: $whitey;
  display: flex;
  height: $height;
  padding: 0;

  > .number {
    font-size: 16px;
    margin: 8px 16px;
  }

  > .title {
    margin: 0;
  }

  > .button {
    line-height: $height;
    margin: 0;
    margin-left: auto;
    padding: 0 50px;
  }
}
